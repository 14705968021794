import axios from 'axios'
console.log(process);
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
 
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    console.log(response)
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if(res.code == undefined){
      return res
    }
    else if (res.code !== 1) {
      console.log(res.code)
      

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 10001 || res.code === 512 || res.code === 514) {
        console.log('login over')

      }

      return Promise.reject(res.message || 'Error')
    }else {
      return res
    }
  },
  error => {
    console.log(error) // for debug
    
    return Promise.reject(error)
  }
)

export default service
