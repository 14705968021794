<template>
    <div class="app-container">
        <div class="app-top">
            <div class="avatar-box" @click="toLogin">
                <img v-if="uid == ''" src="../assets/images/app_saishi_icon.png">
                <img v-else :src="user_info.icon">
                <span v-if="uid == ''">Click To Login</span>
                <span v-else>{{ user_info.nickname }}</span>
                <span v-if="uid != ''" class="logout" @click="logout">Logout</span>
            </div>
            <div v-if="uid != ''" class="blance-box">
                <img src="../assets/images/Tkbicon.png">
                <span>{{ user_info.vip_white == undefined ? 0 : user_info.vip_white.amount }}</span>
            </div>
        </div>
        <div class="app-body">
            <div class="withdraw-box">
                <div class="withdraw-item">
                    <p class="item-label">Wallet Address</p>
                    <div class="withdraw-select" v-if="user_info.vip_white != undefined && user_info.vip_white.address != undefined">
                        <span class="placeholder-span">{{ user_info.vip_white == undefined ? '' : user_info.vip_white.address }}</span>
                    </div>
                    <div class="withdraw-select" @click="connect" v-else>
                        <span class="placeholder-span">Connect Wallet</span>
                    </div>
                </div>
                <div class="withdraw-item">
                    <p class="item-label">Amount</p>
                    <input class="withdraw-input" type="text" name="amount" v-model="amount" placeholder="Amount Number" readonly="">
                </div>
                <div class="withdraw-item">
                    <p class="item-label">Start in</p>
                    <input class="withdraw-input" type="text" name="start_in" v-model="start_in" placeholder="Start in" readonly="">
                </div>
                <div class="withdraw-item">
                    <button class="withdraw-button" @click="toWithdraw">Withdraw</button>
                </div>
                <!-- <div class="withdraw-item">
                    <button class="withdraw-button" @click="withdrawRecord">Withdraw Record</button>
                </div> -->
                <div class="order-record-box">
                    <div class="tip-header">
                        <div class="item-time">
                            <span>Time</span>
                        </div>
                        <div class="item-amount">
                            <span>Amount</span>
                        </div>
                        <div class="item-status">
                            <span>Status</span>
                        </div>
                    </div>
                    <div class="tip-item" v-for="item in order_record" v-bind:key="item.id">
                        <div class="item-time">
                            <span>{{ item.create_time }}</span>
                        </div>
                        <div class="item-amount">
                            <span>{{ item.amount }}</span>
                        </div>
                        <div class="item-status">
                            <span style="color: #4ed34e" v-if="item.signature != '' && item.status == 1" @click="beforeWithdraw(item.order_no,item.amount)">Withdraw</span>
                            <span style="color: #d3f353" v-else-if="item.status == 1">Signature...</span>
                            <span style="color: #4ed34e" v-else-if="item.status == 2">Success</span>
                            <span style="color: red" v-else-if="item.status == 3">Failed</span>
                            <span style="color: #d3f353" v-else-if="item.status == 4">Signature...</span>
                        </div>
                    </div>
                    <div class="page-item">
                        <div class="left-btn" @click="prePage"></div>
                        <div class="right-btn" @click="nextPage"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tip-box" v-if="isShow">
            <div class="tip-close">
                <div class="close" id="closeTip" @click="closeTip"></div>
            </div>
            <div class="tip-bg">
                <div class="tip-title">
                    Select Address
                </div>
                <div class="tip-item" v-for="item in user_accounts" v-bind:key="item">
                    <span>{{ item }}</span>
                </div>
            </div>
        </div>
        <div class="tip-box" v-if="isTipShow">
            <div class="tip-close">
                <div class="close" id="closeTip" @click="closeTip"></div>
            </div>
            <div class="tip-bg">
                <div class="tip-title">
                    Notes
                </div>
                <div class="tip-item">
                    <div class="left">
                        <span>From : </span>
                    </div>
                    <div class="right">
                        <span id="kboxFrom">{{ wallet_address }}</span>
                    </div>
                </div>
                <div class="tip-item">
                    <div class="left">
                        <span>To : </span>
                    </div>
                    <div class="right">
                        <span id="kboxFrom">{{ user_info.vip_white == undefined ? '' : user_info.vip_white.address }}</span>
                    </div>
                </div>
                <div class="tip-item">
                    <div class="left">
                        <span>Amount : </span>
                    </div>
                    <div class="right">
                        <span id="kboxFrom">{{ withdraw_amount }}</span>
                    </div>
                </div>
                <div class="tip-item">
                    <div class="left">
                        <span>GasFee : </span>
                    </div>
                    <div class="right">
                        <span id="kboxFrom">{{ amount_gas_fee }}</span>
                    </div>
                </div>

                <div class="submit-button" @click="doWithdraw">
                    Submit
                </div>
            </div>
        </div>
        <div class="tip-box" v-if="isLoginShow">
            <div class="tip-close">
                <div class="close" id="closeTip" @click="closeLoginTip"></div>
            </div>
            <div class="tip-bg">
                <div class="tip-title">
                    Login
                </div>
                <div class="tip-form-item">
                    <div class="label">
                        <span>Email : </span>
                    </div>
                    <div class="input">
                        <input type="text" name="username" v-model="username">
                    </div>
                </div>
                <div class="tip-form-item">
                    <div class="label">
                        <span>Password : </span>
                    </div>
                    <div class="input">
                        <input type="password" name="password" v-model="password">
                    </div>
                </div>
                <div class="submit-button" @click="doLogin">
                    Login
                </div>
                <div class="submit-button" @click="toRegister">
                    Register
                </div>
            </div>
        </div>
        <div class="tip-box" v-if="isRegisterShow">
            <div class="tip-close">
                <div class="close" id="closeTip" @click="closeRegisterTip"></div>
            </div>
            <div class="tip-bg">
                <div class="tip-title">
                    Login
                </div>
                <div class="tip-form-item">
                    <div class="label">
                        <span>Email : </span>
                    </div>
                    <div class="input">
                        <input type="text" name="username" v-model="username">
                    </div>
                </div>
                <div class="tip-form-item">
                    <div class="label">
                        <span>Code : </span>
                    </div>
                    <div class="tip-form-item-2">
                        <div class="input" style="width: 50%;float: left;">
                            <input type="text" name="username" v-model="code">
                        </div>
                        <div class="get-code">
                            <vue-recaptcha badge="bottomright" class="g-recaptcha" :sitekey="sitekey" size="invisible" @callback="Receive" @expired="recaptchaCallback" @verify="verifyCallback"></vue-recaptcha>
                            <div class="code-button" @click="Receive">{{ code_btn }}</div>
                        </div>
                    </div>
                </div>
                <div class="tip-form-item">
                    <div class="label">
                        <span>Password : </span>
                    </div>
                    <div class="input">
                        <input type="password" name="password" v-model="password">
                    </div>
                </div>
                <div class="submit-button" @click="doRegister">
                    Register
                </div>
                <div class="submit-button" @click="toLogin">
                    Login
                </div>
            </div>
        </div>
        <div class="no-permission" v-if="isPermission">
            <span>You Are No Permission</span>
        </div>
        <div class="tip-box" v-if="isShowOrderRecord">
            <div class="tip-close">
                <div class="close" id="closeTip" @click="closeOrderRecord"></div>
            </div>
            <div class="tip-bg">
                <div class="tip-title">
                    Order Record
                </div>
                <div class="tip-header">
                    <div class="item-time">
                        <span>Time</span>
                    </div>
                    <div class="item-amount">
                        <span>Amount</span>
                    </div>
                    <div class="item-status">
                        <span>Status</span>
                    </div>
                </div>
                <div class="tip-item" v-for="item in order_record" v-bind:key="item.id">
                    <div class="item-time">
                        <span>{{ item.create_time }}</span>
                    </div>
                    <div class="item-amount">
                        <span>{{ item.amount }}</span>
                    </div>
                    <div class="item-status">
                        <span style="color: #4ed34e" v-if="item.signature != '' && item.status == 1" @click="doWithdraw(item.order_no)">Withdraw</span>
                        <span v-else-if="item.status == 1">Waiting...</span>
                        <span style="color: #4ed34e" v-else-if="item.status == 2">Success</span>
                        <span style="color: red" v-else-if="item.status == 3">Failed</span>
                        <span style="color: #d3f353" v-else-if="item.status == 4">Signature...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import "@/css/index.css"
import Web3 from 'web3'
import { tkb, tkb2, tkb_request } from '@/api/utils'
import { setToken, getToken, removeToken } from '@/utils/auth'
import { VueRecaptcha } from 'vue-recaptcha';
var kbox = require('@/abi/kbox.json')

export default {
    name: 'Index',
    data() {
        return {
            kbox_address: '0x3523d58d8036B1C5C9A13493143c97aEfC5Ad422',
            wallet_address: '0x6e11aCf9e88Ec58f696fFD55A0f3E21dcBA8cD41',//0x6e11aCf9e88Ec58f696fFD55A0f3E21dcBA8cD41,0x16489815A8558d88cf9006bDCe956666b22756dd
            notify_host: 'https://bscscan.com/tx/',
            web4: null,
            chainId: 0,
            chain: {},
            ethereums: window.ethereum,
            isShow: false,
            default_account: '',
            user_accounts: [],
            isLoginShow: false,
            username: '',
            password: '',
            user_info: {},
            uid: '',
            isPermission: false,
            amount: '',
            start_in: '',
            isShowOrderRecord: false,
            order_record: [],
            record_page: 1,
            record_limit: 4,
            order_record_count: 0,
            isRegisterShow: false,
            code_btn: 'Receive',
            code: '',
            receive_status: 1,
            timer: null,
            isTipShow: false,
            amount_gas_fee: 0,
            gas_fee: 76572,
            withdraw_amount: 0,
            withdraw_order: 0,
            sitekey: '6LffKoMeAAAAAGbDOFyqyeTikAscJdJkPzZKlofe',
            verify_code: ''
        }
    },
    components: { VueRecaptcha },
    created() {
        if (typeof window.ethereum !== 'undefined') {
            this.web4 = new Web3(window.ethereum)
        } else if (window.web3 !== 'undefined') {
            this.web4 = new Web3(window.web3.currentProvider)
        } else {
            alert("alert", 'MetaMask is required', null, null);
        }

        this.chainId = this.web4.utils.numberToHex('56');
        this.chain = {
            chainId: this.chainId,
            chainName: 'BSC',
            nativeCurrency: {
                name: 'BSC',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com']
        }

        this.ethereums.on('accountsChanged', (accounts) => {
            console.log(accounts);
            location.reload();
        });

        this.changeChain()
        this.getUid()
        this.getUserInfo()
        this.getOrderRecord()
        

    },
    mounted() {
        this.getOrderRecordTimes()
    },
    methods: {

        changeChain() {
            this.web4.eth.net.getId().then((res) => {

                if (res == this.chain.chainId) {
                    return true;
                }
                try {
                    this.ethereums.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: this.chainId }],
                    }).then();
                } catch (switchError) {
                    this.ethereums.request({
                        method: 'wallet_addEthereumChain',
                        params: [this.chain],
                    }).then();
                }
            })

            return true;
        },
        selectAddress() {
            console.log('selectAddress');
            this.isShow = true;
        },
        closeTip() {
            this.isTipShow = false;
        },
        defaultAccount() {
            var that = this;
            that.ethereums.request({ method: 'eth_requestAccounts' }).then(function(accounts) {

                that.user_accounts = accounts;
                let _defaultAccount = accounts[0];

                console.log('defaultAccount')
                console.log(_defaultAccount)
                console.log(that.user_info)

                if (_defaultAccount != that.user_info.vip_white.address) {
                    that.user_info.vip_white = {}
                }
            });
        },
        toLogin() {

            if (this.uid == '' || this.uid == null) {
                this.isRegisterShow = false;
                this.isLoginShow = true;
            }
        },
        closeLoginTip() {
            this.isLoginShow = false;
        },
        closeRegisterTip() {
            this.isRegisterShow = false;
        },
        toRegister() {
            this.isLoginShow = false;
            this.isRegisterShow = true;
        },
        // toLogin(){
        //     this.isRegisterShow = false;
        //     this.isLoginShow = true;
        // },
        doRegister() {
            let data = {
                "type": "userRegister",
                "timestamp": Date.parse(new Date()),
                "language": 'en',
                "account_type": 2,
                "email_account": this.username,
                "password": this.password,
                "confirm": this.password,
                'referral_code': '',
                'sociaty_referral_code': '',
                "auth": this.code,
                "tel_account": '',
                "tel_prefix": '',
                "channel": 'mobile',
                "client_ip": 0
            };
            this.$loading.show()
            console.log(data);
            tkb2(data).then((res) => {
                console.log(res);
                console.log(JSON.parse(res.params))
                tkb_request(res.url, JSON.parse(res.params)).then((result) => {
                    console.log(result)
                    this.$loading.close()
                    this.$alert('success',() => {
                        location.reload();
                    });
                }).catch((err) => {
                    console.log(err);
                    this.$alert(err);
                    this.$loading.close()
                })
            }).catch(() => {

            })
        },
        doLogin() {
            this.$loading.show()

            let data = {
                type: 'userLogin',
                timestamp: Date.parse(new Date()),
                language: 'en',
                account_type: 2,
                email_account: this.username,
                password: this.password,
                client_ip: 0
            }

            tkb(data).then((res) => {
                console.log(res);
                setToken(res.data.uid)
                this.isLoginShow = false;
                this.$loading.close()
                location.reload();
            }).catch((err) => {
                console.log(err);
                this.$alert(err);
                this.$loading.close()
            })
        },
        logout() {
            this.$confirm('Are you sure you want to exit the account', function() {
                console.log('logout')
                this.uid = '';
                removeToken()
                location.reload();
            })
        },
        getUserInfo() {
            console.log(this.uid)
            if (this.uid !== '') {
                this.$loading.show()

                let data = {
                    type: 'userInfo',
                    timestamp: Date.parse(new Date()),
                    language: 'en',
                    uid: this.uid,
                }

                tkb(data).then((res) => {
                    console.log(res);
                    this.user_info = res.data
                    this.$loading.close()
                    console.log(this.user_info.wallet_address != '' && this.user_info.vip_white.length == 0);
                    
                    if (this.user_info.vip_white.amount != undefined) {
                        this.user_info.vip_white.amount = parseInt(this.user_info.vip_white.amount)
                    }

                    var that = this;
                    that.ethereums.request({ method: 'eth_requestAccounts' }).then(function(accounts) {

                        that.user_accounts = accounts;
                        let _defaultAccount = accounts[0];

                        if (that.user_info.vip_white.address != undefined && _defaultAccount.toLowerCase() != that.user_info.vip_white.address.toLowerCase()) {
                            that.user_info.vip_white = {}
                        } else {
                            that.amount = that.user_info.vip_white.amount
                            that.start_in = that.format(that.user_info.vip_white.withdraw_time);
                        }
                    });

                    this.changeAddress()
                }).catch((err) => {
                    console.log(err);
                    this.user_info = {}
                    this.uid = ''
                    this.$loading.close()
                })
            }
        },
        getUid() {
            let uid = getToken();
            console.log(uid);
            this.uid = uid === undefined ? '' : uid;
        },
        connect() {

            var that = this;

            if (that.uid !== '') {
                that.ethereums.request({ method: 'eth_requestAccounts' }).then(function(accounts) {
                    that.user_accounts = accounts;
                    let _defaultAccount = accounts[0];

                    if (_defaultAccount) {

                        let message = 'verify address';

                        that.ethereums.request({ method: 'personal_sign', params: [message, _defaultAccount, Date.now()] })
                            .then(function(resp) {

                                that.default_account = _defaultAccount
                                that.VerifyAddr(that.uid, resp, _defaultAccount)
                                this.getUserInfo();

                            }).catch(console.log);
                    }
                });
            }
        },
        changeAddress() {

            var that = this;

            if (that.user_info.wallet_address == '' && that.user_info.vip_white.address != undefined) {
                let message = 'verify address';
                that.ethereums.request({ method: 'personal_sign', params: [message, that.user_info.vip_white.address, Date.now()] })
                    .then(function(resp) {

                        that.VerifyAddr(that.uid, resp, that.user_info.vip_white.address)

                    }).catch(console.log);
            }
        },
        VerifyAddr(uid, signature, wallet_address) {
            this.$loading.show()
            let data = {
                "type": "userVerifyAddr",
                "timestamp": Date.parse(new Date()) / 1000,
                "uid": uid,
                "language": 'en',
                "signature": signature,
                "wallet_address": wallet_address
            };
            tkb(data).then((res) => {
                console.log(res);
                this.$loading.close()
            }).catch(() => {
                this.$loading.close()
            })
        },
        withdrawRecord() {
            console.log('withdrawRecord')
            this.isShowOrderRecord = true;
            this.getOrderRecord()
        },
        closeOrderRecord() {
            this.isShowOrderRecord = false;
        },
        beforeWithdraw(order_no,amount){

            if (this.uid == '') {
                this.$alert('Please Log in First');
                return false;
            }

            if (order_no == '' || order_no == undefined || amount == '' || amount == undefined) {
                this.$alert('Please Select Order First');
                return false;
            }

            this.withdraw_amount = amount;
            this.withdraw_order = order_no;

            var that = this;

            that.web4.eth.getGasPrice().then(function(val) {

                console.log(val);
                that.amount_gas_fee = that.web4.utils.fromWei(val, 'ether') * that.gas_fee + ' BNB'
            })
            that.isTipShow = true;
        },
        toWithdraw() {
            console.log('toWithdraw')

            var that = this;

            if (that.uid == '') {
                that.$alert('Please Log in First');
                return false;
            }

            if (that.user_info.vip_white.address == undefined || that.user_info.vip_white.address == '' || that.user_info.vip_white.address == null) {
                that.$alert('You Have Not Permission')
                return false;
            }

            if (that.amount == '' || that.amount == null) {
                that.$alert('Please Fill in The Amount')
                return false;
            }

            if (!that.IsNumber(that.amount)) {
                that.$alert('The Amount Must Be A Numeric Value')
                return false;
            }

            let timestamp = Date.parse(new Date()) / 1000;

            if (that.user_info.vip_white.withdraw_time > timestamp) {
                that.$alert('Not within the allowable time range')
                return false;
            }

            that.$loading.show()
            let data = {
                "type": "createVipWithdrawOrder",
                "timestamp": timestamp,
                "uid": that.uid,
                "language": 'en',
                "address": that.user_info.vip_white.address,
                "amount": that.amount
            };
            tkb(data).then((res) => {
                console.log(res);
                that.$loading.close()

                if (res.code == 1) {
                    that.user_info.vip_white.amount = res.data.coin
                    that.amount = ''

                    this.$alert('Submitted Successfully , Please Wait For Signature', () => {
                        // that.withdrawRecord();
                        this.getOrderRecord()
                        that.isTipShow = false;
                    })

                } else {
                    that.isTipShow = false;
                    that.$alert(res.message)
                }

            }).catch(() => {
                that.isTipShow = false;
                that.$alert('Submitted Failed')
                that.$loading.close()
            })
        },
        IsNumber(val) {
            let result = /^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$/.test(val);
            return result
        },
        getOrderRecord() {
            var that = this;

            if (that.uid !== '') {
                that.$loading.show()
                let data = {
                    "type": "vipOrderRecord",
                    "timestamp": Date.parse(new Date()),
                    "uid": that.uid,
                    "language": 'en',
                    "record_page": that.record_page,
                    "record_limit": that.record_limit,
                    'page_time': Date.parse(new Date()) / 1000
                };

                tkb(data).then((res) => {
                    console.log(res);
                    that.$loading.close()
                    that.order_record = res.data.record_list
                    that.order_record_count = res.data.count
                }).catch(() => {
                    that.$alert('Get Failed')
                    that.$loading.close()
                })
            }
        },
        getOrderRecordTimes() { //定义了一个times()方法来执行定时查询findDB()
            console.log('getOrderRecordTimes')
            if (this.uid !== '') {
                return setInterval(() => { //setInterval换个名字就行了
                    this.getOrderRecord()
                    this.refresh()
                }, 30000) //设置时间，这里是30s
            }
        },
        doWithdraw(order_no = '') {
            console.log('doWithdraw')

            if (this.withdraw_order == 0 || this.withdraw_order == '') {
                this.$alert('Please Select Order First');
                return false;
            }

            order_no = this.withdraw_order;

            this.$loading.show()

            let order_detail_data = {
                "type": "getTkbWithdrawOrder",
                "timestamp": Date.parse(new Date()),
                "uid": this.uid,
                "order_no": order_no,
                "language": 'en'
            };

            tkb(order_detail_data).then((result) => {
                console.log(result);

                if (result.data.signature == '' || result.data.signature == undefined || result.data.signature == null) {
                    this.$alert('Under Review...', () => {
                        this.$loading.close()
                    });
                    this.isTipShow = false;
                    return false;
                }

                this.$loading.show('Wallet Connecting...')

                var ether = this.web4.utils.toWei(result.data.amount, 'ether');
                var address = result.data.address;

                var contract = new this.web4.eth.Contract(kbox, this.wallet_address);

                contract.methods.getCodeMapKbox(order_no).call({ from: address }, (err, resc) => {
                    console.log(err)
                    console.log(resc)

                    this.$loading.show('Withdrawing...')

                    if (resc == false) {

                        contract.methods.withdrawKox(address, ether, result.data.order_no, result.data.create_time, result.data.signature).send({ from: address }, (err, res) => {
                            this.isTipShow = false;
                            this.$loading.show('Get Results...')
                            console.log(err)
                            console.log(res)
                            if (err != undefined) {
                                this.$alert(err.message);
                                this.$loading.close()
                            }
                        }).on('receipt', function(receipt) {
                            this.isTipShow = false;
                            console.log(receipt)
                            this.orderSuccess(order_no)
                            this.$loading.close()
                        })
                    } else {
                        this.isTipShow = false;
                        this.$loading.show('Order Is Success')
                        this.orderSuccess(order_no)
                        this.$loading.close()
                    }

                })

                console.log(contract);

            }).catch((err) => {
                this.isTipShow = false;
                console.log(err)
                this.$alert('Get Failed')
                this.$loading.close()
            })

        },
        orderSuccess(order_no) {
            let data = { "type": "tkbWithdrawOrderSuccess", "timestamp": Date.parse(new Date()), "uid": this.uid, "order_no": order_no, "language": 'en' };
            tkb(data).then((res) => {
                console.log(res);

            }).catch((err) => {
                console.log(err)
            })
        },
        nextPage() {

            if (this.order_record_count < this.record_limit) {
                return false;
            }

            this.$loading.show()

            let page = this.record_page + 1;
            this.record_page = page;

            let data = {
                "type": "vipOrderRecord",
                "timestamp": Date.parse(new Date()),
                "uid": this.uid,
                "language": 'en',
                "record_page": page,
                "record_limit": this.record_limit,
                'page_time': Date.parse(new Date()) / 1000
            };

            tkb(data).then((res) => {
                console.log(res);
                this.$loading.close()
                this.order_record = res.data.record_list
                this.order_record_count = res.data.count

            }).catch(() => {
                this.$alert('Get Failed')
                this.$loading.close()
            })
        },
        prePage() {

            if (this.record_page == 1) {
                return false;
            }

            this.$loading.show()

            let page = this.record_page - 1;
            this.record_page = page;

            let data = {
                "type": "vipOrderRecord",
                "timestamp": Date.parse(new Date()),
                "uid": this.uid,
                "language": 'en',
                "record_page": page,
                "record_limit": this.record_limit,
                'page_time': Date.parse(new Date()) / 1000
            };

            tkb(data).then((res) => {
                console.log(res);
                this.$loading.close()
                this.order_record = res.data.record_list
                this.order_record_count = res.data.count

            }).catch(() => {
                this.$alert('Get Failed')
                this.$loading.close()
            })
        },
        recaptchaCallback(){
            console.log('recaptchaCallback')
        },
        verifyCallback(res){
            console.log('verifyCallback')
           
            this.verify_code = res;

            let data = {
                "type": "userAuthCode",
                "timestamp": Date.parse(new Date()),
                "language": 'en',
                "email": this.username,
                "tel_number": '',
                "tel_prefix": '',
                "version": 1,
                "g-recaptcha-response": res
            };
            this.$loading.show()
            console.log(data);
            tkb(data).then((res) => {
                console.log(res);
                this.$alert('Send Success')
                this.$loading.close()
                this.receiveD()
            }).catch((err) => {
                console.log(err)
                this.$alert(err)
                this.$loading.close()
                this.code_btn ='Receive'
                this.receive_status = 1;
            })
        },
        Receive() {
            console.log('Receive');

            if (this.receive_status != 1) {
                return false
            }

            if (this.username == '' || this.username == null) {
                this.$alert('Please Fill In Email')
                return false
            }

            this.code_btn = 'Send...';
            this.receive_status = 0;

            window.grecaptcha.execute();

            // grecaptcha.then((res,val) => {
            //     console.log(res);
            //     console.log(val);
            //     console.log(this.verify_code);
            // }).catch((err) => {
            //     console.log(err)
            // })

            // console.log(grecaptcha);

            // let data = {
            //     "type": "userAuthCode",
            //     "timestamp": Date.parse(new Date()),
            //     "language": 'en',
            //     "email": this.username,
            //     "tel_number": '',
            //     "tel_prefix": '',
            //     "version": 1,
            //     "g-recaptcha-response": ''
            // };
            // this.$loading.show()
            // console.log(data);
            // tkb(data).then((res) => {
            //     console.log(res);
            //     this.$loading.close()
            //     this.receiveD()
            // }).catch(() => {
            //     this.$alert('Get Failed')
            //     this.$loading.close()
            //     this.code_btn ='Receive'
            //     this.receive_status = 1;
            // })
        },
        refresh(){
            if (this.uid !== '') {
                let data = {
                    "type": "refresh",
                    "timestamp": Date.parse(new Date()),
                    "language": 'en',
                    "version": 1,
                    'uid': this.uid,
                };
                tkb(data).then((res) => {
                    console.log(res);
                }).catch(() => {
                    
                })
            }
            
        },
        receiveD(){
            if(this.timer){
                clearInterval(this.timer)
            }
            let tim = 60;
            this.timer = setInterval(()=>{
                tim--;
                if(tim === 0){
                    clearInterval(this.timer)
                    this.code_btn ='Receive'
                    this.receive_status = 1;
                }else{
                    this.code_btn = `${tim} (s)`
                }
            },1000)
        },
        add0(m){return m<10?'0'+m:m },
        format(shijianchuo)
        {
            var time = new Date(shijianchuo * 1000);
            var y = time.getFullYear();
            var m = time.getMonth()+1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y+'-'+this.add0(m)+'-'+this.add0(d)+' '+this.add0(h)+':'+this.add0(mm)+':'+this.add0(s);
        }
    }
}
</script>
<style>
html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url('../assets/images/bg_1.jpg') no-repeat;
    background-size: 100% 100%;
    font-family: "AGENCYR" !important;
}

#id {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 750px) {
    .app-container {
        max-width: 100%;
        height: 100%;
        background: url('../assets/images/bg.jpg') no-repeat;
        background-size: 100% 100%;
    }

    .app-top {
        width: 100%;
        height: .6rem;
        background: url('../assets/images/tb.png') no-repeat;
        background-size: 100% 100%;
        font-size: .15rem;
        color: #fff;
        line-height: .6rem;
        box-sizing: border-box;
        padding: .1rem .2rem;
    }

    .avatar-box {
        float: left;
        line-height: .4rem;
        height: .4rem;
    }

    .avatar-box img {
        width: .4rem;
        height: .4rem;
        border-radius: .4rem;
        vertical-align: middle;
    }

    .avatar-box span {
        vertical-align: middle;
        padding-left: .1rem;
    }

    .app-body {
        width: 100%;
        padding: .2rem .2rem;
        box-sizing: border-box;
    }

    .withdraw-box {
        /*height: 620px;*/
        margin: 0 auto;
        border-top-width: 30px;
        border-left-width: 14px;
        border-right-width: 14px;
        border-bottom-width: 30px;
        border-color: #000;
        border-style: solid;
        border-image-source: url(../assets/images/framesmall.png);
        border-image-slice: 58 34 58 34 fill;
    }

    .item-label {
        font-size: .14rem;
        color: #fff;
        padding: 0;
        margin: 0;
        text-align: left;
    }

    .withdraw-item {
        margin-bottom: .2rem;
    }

    .withdraw-input {
        display: block;
        margin-top: 0.1rem;
        width: 100%;
        padding: 0 .1rem;
        border-width: 0;
        outline-style: none;
        height: 0.4rem;
        color: #fff;
        border-top-width: 0px;
        border-left-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 0px;
        border-color: #000;
        border-style: solid;
        border-image-source: url('../assets/images/grayframesmall.png');
        border-image-slice: 0 10 0 10 fill;
        box-sizing: border-box;
        background-color: #fff0
    }

    .withdraw-button {
        display: block;
        width: 2rem;
        height: 0.4rem;
        background-color: #fff0;
        border: 0;
        color: #fff;
        background: url(../assets/images/orangeButton.png);
        background-size: 100% 100%;
        margin: 0 auto;
    }

    .withdraw-select {
        display: block;
        margin-top: 0.1rem;
        width: 100%;
        border-width: 0;
        border-color: #fff0;
        background-color: #fff0;
        height: 0.4rem;
        outline-style: none;
        color: #fff;
        border-top-width: 0px;
        border-left-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 0px;
        border-color: #000;
        border-style: solid;
        border-image-source: url('../assets/images/grayframesmall.png');
        border-image-slice: 0 10 0 10 fill;
        box-sizing: border-box;
        background-color: #fff0;
        line-height: .4rem;
        text-align: left;
        padding: 0 .1rem;
        color: #a0a0a0;
    }

    .withdraw-select ul {
        margin: 0;
    }

    .withdraw-select ul li {
        width: 100%;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        padding-left: 10px;
        font-size: .15rem;
    }

    .withdraw-select ul li:hover {}

    .placeholder-span {
        font-size: .12rem;
        display: block;
    }

    .tip-box {
        width: 100%;
        height: 100%;
        position: fixed;
        top: .6rem;
        background-color: #000;
        padding-top: 20px;
    }

    .tip-bg {
        width: 90%;
        max-width: 3.5rem;
        /*height: 3rem;*/
        margin: 0 auto;
        border-top-width: .46rem;
        border-left-width: .12rem;
        border-right-width: .12rem;
        border-bottom-width: .36rem;
        border-color: #000;
        border-style: solid;
        border-image-source: url(../assets/images/OpenboxBg.png);
        border-image-slice: 100 40 80 36 fill;
    }

    .tip-close {
        width: 90%;
        height: 28px;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .close {
        background: url(../assets/images/close.png) no-repeat;
        background-size: 100% 100%;
        width: 28px;
        height: 28px;
        float: right;
    }

    .tip-bg .tip-title {
        color: #fff;
        width: 100%;
        text-align: center;
        margin-top: -.32rem;
        font-size: .2rem
    }

    .tip-bg .tip-header {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .36rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .36rem;
        font-size: .12rem;
        color: #fff;
        box-sizing: border-box;
    }

    .item-time {
        width: 40%;
        float: left;
    }

    .item-amount {
        width: 30%;
        float: left;
    }

    .item-status {
        width: 30%;
        float: left;
    }

    .tip-bg .tip-item {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .36rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .36rem;
        font-size: .1rem;
        color: #fff;
        box-sizing: border-box;
    }

    .tip-bg .tip-item .left {
        width: 20%;
        float: left;
        text-align: left;
    }

    .tip-bg .tip-item .right {
        width: 80%;
        float: right;
        text-align: right;
    }

    .tip-bg .tip-form-item {
        width: 100%;
        margin-top: .1rem;
        padding: 0 0.1rem;
        font-size: .12rem;
        color: #fff;
        box-sizing: border-box;
    }

    .tip-bg .tip-form-item .label {
        text-align: left;
        display: block;
        height: .36rem;
        line-height: .36rem;
        width: 100%;
    }

    .tip-bg .tip-form-item .input {
        height: .36rem;
        line-height: .36rem;
        text-align: left;
        display: block;
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
    }

    .tip-bg .tip-form-item .input input {
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        height: 0.36rem;
        background-color: #fff0;
        border-width: 0;
        outline: none;
        color: #fff;
        padding: 0 0.1rem;
    }

    .tip-bg .tip-item .right .fee-box {
        background: url(../assets/images/grayframesmall.png) no-repeat;
        background-size: 100% 100%;
        height: 34px;
        text-align: center;
        line-height: 34px;
        margin-top: 8px;
        float: right;
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #0bb90b;
    }

    .submit-button {
        width: 1.6rem;
        height: .4rem;
        background: url(../assets/images/orangeButton.png) no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: .4rem;
        color: #fff;
        margin: 0 auto;
        margin-top: .15rem;
        font-size: .14rem
    }

    .blance-box {
        display: block;
        float: right;
        height: 0.4rem;
        line-height: .4rem;
        vertical-align: middle;
    }

    .blance-box img {
        height: 0.2rem;
        width: 0.2rem;
        vertical-align: middle;
        margin-right: .1rem;
    }

    .no-permission {
        width: 100%;
        height: 100%;
        line-height: 5rem;
        font-size: .2rem;
        color: #fff;
        background-color: #08101ca3;
        position: fixed;
        top: 0.6rem;
    }

    .order-record-box {
        width: 100%;
        height: 3rem;
    }

    .order-record-box .tip-header {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .36rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .36rem;
        font-size: .12rem;
        color: #fff;
        box-sizing: border-box;
    }

    .item-time {
        width: 40%;
        float: left;
    }

    .item-amount {
        width: 30%;
        float: left;
    }

    .item-status {
        width: 30%;
        float: left;
    }

    .order-record-box .tip-item {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .36rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .36rem;
        font-size: .12rem;
        color: #fff;
        box-sizing: border-box;
    }

    .order-record-box .page-item {
        width: 100%;
        height: .36rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .36rem;
        font-size: .12rem;
        color: #fff;
        box-sizing: border-box;
        padding-left: 1rem;
    }

    .left-btn {
        width: .24rem;
        height: .24rem;
        background: url(../assets/images/LeftBtn.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 0.06rem;
        float: left;
    }

    .right-btn {
        width: .24rem;
        height: .24rem;
        background: url(../assets/images/RightBtn.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 0.06rem;
        float: left;
        margin-left: 0.2rem;
    }

    .get-code {
        width: 50%;
        float: right;
        padding: 0 0.3rem;
        box-sizing: border-box;
    }

    .code-button {
        width: 100%;
        height: 0.4rem;
        background: url(../assets/images/GetBackButton.png) no-repeat;
        background-size: 100% 100%;
        line-height: .4rem;
    }

    .tip-form-item-2{
        width: 100%;
        height: .4rem
    }
}

@media (min-width: 750px) {
    .app-container {
        max-width: 2.5rem;
        height: 100%;
        margin: 0 auto;
        background: url('../assets/images/bg.jpg') no-repeat;
        background-size: 100% 100%;
    }

    .app-top {
        width: 100%;
        height: .3rem;
        background: url('../assets/images/tb.png') no-repeat;
        background-size: 100% 100%;
        font-size: .08rem;
        color: #fff;
        line-height: .3rem;
        box-sizing: border-box;
        padding: .05rem .1rem;
    }

    .avatar-box {
        float: left;
        line-height: .2rem;
        height: .2rem;
    }

    .avatar-box img {
        width: .2rem;
        height: .2rem;
        border-radius: .2rem;
        vertical-align: middle;
    }

    .avatar-box span {
        vertical-align: middle;
        padding-left: .1rem;
    }

    .app-body {
        width: 100%;
        padding: .1rem .1rem;
        box-sizing: border-box;
    }

    .withdraw-box {
        /*height: 620px;*/
        margin: 0 auto;
        border-top-width: 30px;
        border-left-width: 14px;
        border-right-width: 14px;
        border-bottom-width: 30px;
        border-color: #000;
        border-style: solid;
        border-image-source: url(../assets/images/framesmall.png);
        border-image-slice: 58 34 58 34 fill;
    }

    .item-label {
        font-size: .07rem;
        color: #fff;
        padding: 0;
        margin: 0;
        text-align: left;
    }

    .withdraw-item {
        margin-bottom: .1rem;
    }

    .withdraw-input {
        display: block;
        margin-top: 0.1rem;
        width: 100%;
        padding: 0 .1rem;
        border-width: 0;
        outline-style: none;
        height: 0.2rem;
        color: #fff;
        border-top-width: 0px;
        border-left-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 0px;
        border-color: #000;
        border-style: solid;
        border-image-source: url('../assets/images/grayframesmall.png');
        border-image-slice: 0 10 0 10 fill;
        box-sizing: border-box;
        background-color: #fff0
    }

    .withdraw-button {
        display: block;
        width: 1rem;
        height: 0.2rem;
        background-color: #fff0;
        border: 0;
        color: #fff;
        background: url(../assets/images/orangeButton.png);
        background-size: 100% 100%;
        margin: 0 auto;
    }

    .withdraw-select {
        display: block;
        margin-top: 0.1rem;
        width: 100%;
        border-width: 0;
        border-color: #fff0;
        background-color: #fff0;
        height: 0.2rem;
        outline-style: none;
        color: #fff;
        border-top-width: 0px;
        border-left-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 0px;
        border-color: #000;
        border-style: solid;
        border-image-source: url('../assets/images/grayframesmall.png');
        border-image-slice: 0 10 0 10 fill;
        box-sizing: border-box;
        background-color: #fff0;
        line-height: .2rem;
        text-align: left;
        padding: 0 .1rem;
        color: #a0a0a0;
    }

    .withdraw-select ul {
        margin: 0;
    }

    .withdraw-select ul li {
        width: 100%;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        padding-left: 10px;
        font-size: .15rem;
    }

    .withdraw-select ul li:hover {}

    .placeholder-span {
        font-size: .08rem;
        display: block;
    }

    .tip-box {
        width: 2.5rem;
        height: 100%;
        position: fixed;
        top: .3rem;
        background-color: #000;
        padding-top: 20px;
    }

    .tip-bg {
        width: 90%;
        max-width: 3.5rem;
        /*height: 3rem;*/
        margin: 0 auto;
        border-top-width: .23rem;
        border-left-width: .06rem;
        border-right-width: .06rem;
        border-bottom-width: .18rem;
        border-color: #000;
        border-style: solid;
        border-image-source: url(../assets/images/OpenboxBg.png);
        border-image-slice: 100 40 80 36 fill;
    }

    .tip-close {
        width: 90%;
        height: 28px;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .close {
        background: url(../assets/images/close.png) no-repeat;
        background-size: 100% 100%;
        width: 28px;
        height: 28px;
        float: right;
    }

    .tip-bg .tip-title {
        color: #fff;
        width: 100%;
        text-align: center;
        margin-top: -.16rem;
        font-size: .1rem
    }

    .tip-bg .tip-header {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .18rem;
        margin-top: .08rem;
        padding: 0 0.1rem;
        line-height: .19rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
    }

    .item-time {
        width: 40%;
        float: left;
    }

    .item-amount {
        width: 30%;
        float: left;
    }

    .item-status {
        width: 30%;
        float: left;
    }

    .tip-bg .tip-item {
        background: url(/img/grayframe.25687eba.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 0.18rem;
        margin-top: 0.15rem;
        padding: 0 0.1rem;
        line-height: .18rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
    }

    .tip-bg .tip-item .left {
        width: 30%;
        float: left;
        text-align: left;
    }

    .tip-bg .tip-item .right {
        width: 70%;
        float: right;
        text-align: right;
    }

    .tip-bg .tip-form-item {
        width: 100%;
        margin-top: .06rem;
        padding: 0 0.1rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
    }

    .tip-bg .tip-form-item .label {
        text-align: left;
        display: block;
        height: .18rem;
        line-height: .18rem;
        width: 100%;
    }

    .tip-bg .tip-form-item .input {
        height: .18rem;
        line-height: .18rem;
        text-align: left;
        display: block;
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
    }

    .tip-bg .tip-form-item .input input {
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        height: 0.18rem;
        background-color: #fff0;
        border-width: 0;
        outline: none;
        color: #fff;
        padding: 0 0.1rem;
    }

    .tip-bg .tip-item .right .fee-box {
        background: url(../assets/images/grayframesmall.png) no-repeat;
        background-size: 100% 100%;
        height: 34px;
        text-align: center;
        line-height: 34px;
        margin-top: 8px;
        float: right;
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #0bb90b;
    }

    .submit-button {
        width: .8rem;
        height: .2rem;
        background: url(../assets/images/orangeButton.png) no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: .2rem;
        color: #fff;
        margin: 0 auto;
        margin-top: .12rem;
        font-size: .07rem
    }

    .blance-box {
        display: block;
        float: right;
        height: 0.2rem;
        line-height: .2rem;
        vertical-align: middle;
    }

    .blance-box img {
        height: 0.1rem;
        width: 0.1rem;
        vertical-align: middle;
        margin-right: .05rem;
    }

    .no-permission {
        width: 100%;
        height: 100%;
        line-height: 5rem;
        font-size: .2rem;
        color: #fff;
        background-color: #08101ca3;
        position: fixed;
        top: 0.6rem;
    }

    .order-record-box {
        width: 100%;
        height: 2rem;
    }

    .order-record-box .tip-header {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .18rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .18rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
    }

    .item-time {
        width: 40%;
        float: left;
    }

    .item-amount {
        width: 30%;
        float: left;
    }

    .item-status {
        width: 30%;
        float: left;
    }

    .order-record-box .tip-item {
        background: url(../assets/images/grayframe.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: .18rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .18rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
    }

    .order-record-box .page-item {
        width: 100%;
        height: .18rem;
        margin-top: .15rem;
        padding: 0 0.1rem;
        line-height: .18rem;
        font-size: .06rem;
        color: #fff;
        box-sizing: border-box;
        padding-left: 1rem;
    }

    .left-btn {
        width: .12rem;
        height: .12rem;
        background: url(../assets/images/LeftBtn.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 0.06rem;
        float: left;
    }

    .right-btn {
        width: .12rem;
        height: .12rem;
        background: url(../assets/images/RightBtn.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 0.06rem;
        float: left;
        margin-left: 0.2rem;
    }

    .get-code {
        width: 50%;
        float: right;
        padding: 0 0.15rem;
        box-sizing: border-box;
    }

    .code-button {
        width: 100%;
        height: 0.2rem;
        background: url(../assets/images/GetBackButton.png) no-repeat;
        background-size: 100% 100%;
        line-height: .2rem;
        font-size: .07rem;
    }

    .tip-form-item-2{
        width: 100%;
        height: .2rem
    }
}
</style>