import request from '@/utils/request'

export function tkb(data) {
  return request({
    url: '/api/api.php',
    method: 'post',
    data
  })
}

export function tkb2(data) {
  return request({
    url: '/api/api2.php',
    method: 'post',
    data
  })
}

export function tkb_request(url,data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}